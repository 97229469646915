import type { RouteLocationNormalizedLoaded } from '#vue-router';

export const useSpecialsStore = defineStore('specials', () => {
    const appConfig = useAppConfig();
    const dealersStore = useDealersStore();
    const specialsApi = new SpecialsApi();

    // Status
    const status = ref<Status>('initial');

    // Banner
    const banner = ref('');
    const bannerUrl = ref('');
    const GET_BANNER = async () => {
        try {
            const response = await specialsApi.getSpecialsBannerText(
                appConfig.arkonas.site
            );

            banner.value = response.text;
            bannerUrl.value = response.url;
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    // Unique Make & Models for navigation links
    const uniqueMakeModels = shallowRef<Readonly<SpecialsNewUniqueMakeModel>[]>(
        []
    );
    const GET_UNIQUE_NEW_MAKE_MODELS = async () => {
        try {
            uniqueMakeModels.value =
                await specialsApi.getNewInventoryUniqueMakeModels(
                    appConfig.arkonas.site
                );
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    // New Specials
    const specialsNew = shallowRef<Readonly<SpecialsNew>[]>([]);
    const disclaimerNew = ref('');
    const GET_NEW = async () => {
        try {
            status.value = 'loading';

            specialsNew.value = await specialsApi.getNewInventorySpecials(
                appConfig.arkonas.site
            );

            disclaimerNew.value = await specialsApi.getNewSpecialsDisclaimer(
                appConfig.arkonas.site
            );

            status.value = 'loaded';
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
            status.value = 'error';
        }
    };

    // Used Specials
    const specialsUsed = shallowRef<Readonly<SpecialsUsed | SpecialsDay45>[]>(
        []
    );
    const filteredUsed = computed(() => {
        if (appConfig.specialsOptions.used.filterByMake) {
            return specialsUsed.value.filter((special) =>
                dealersStore.currentDealer.makes.includes(special.make)
            );
        }

        return specialsUsed.value;
    });
    const GET_USED = async () => {
        try {
            status.value = 'loading';

            if (
                appConfig.specialsOptions.used.useDay45 &&
                appConfig.specialsOptions.used.limit
            ) {
                specialsUsed.value =
                    await specialsApi.getUsedInventorySpecialsDay45WithLimit(
                        appConfig.arkonas.site,
                        appConfig.specialsOptions.used.limit
                    );
            } else if (appConfig.specialsOptions.used.useDay45) {
                specialsUsed.value =
                    await specialsApi.getUsedInventorySpecialsDay45(
                        appConfig.arkonas.site
                    );
            } else {
                specialsUsed.value = await specialsApi.getUsedInventorySpecials(
                    appConfig.arkonas.site
                );
            }

            status.value = 'loaded';
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
            status.value = 'error';
        }
    };

    // Certified Specials
    const specialsCpo = shallowRef<Readonly<SpecialsUsed | SpecialsDay45>[]>(
        []
    );
    const filteredCpo = computed(() => {
        if (appConfig.specialsOptions.cpo.filterByMake) {
            return specialsCpo.value.filter((special) =>
                dealersStore.currentDealer.makes.includes(special.make)
            );
        }

        return specialsCpo.value;
    });
    const GET_CPO = async () => {
        try {
            status.value = 'loading';

            if (
                appConfig.specialsOptions.cpo.useDay45 &&
                appConfig.specialsOptions.cpo.limit
            ) {
                specialsCpo.value =
                    await specialsApi.getCPOInventorySpecialsDay45WithLimit(
                        appConfig.arkonas.site,
                        appConfig.specialsOptions.cpo.limit
                    );
            } else if (appConfig.specialsOptions.cpo.useDay45) {
                specialsCpo.value =
                    await specialsApi.getCPOInventorySpecialsDay45(
                        appConfig.arkonas.site
                    );
            } else {
                specialsCpo.value = await specialsApi.getCPOInventorySpecials(
                    appConfig.arkonas.site
                );
            }

            status.value = 'loaded';
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
            status.value = 'error';
        }
    };

    // Clearance Specials
    const specialsClearance = shallowRef<Readonly<SpecialsClearance>[]>([]);
    const GET_CLEARANCE = async () => {
        try {
            status.value = 'loading';

            specialsClearance.value = await specialsApi.getClearanceSpecials(
                appConfig.arkonas.site,
                appConfig.clearanceOptions.year
            );

            status.value = 'loaded';
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
            status.value = 'error';
        }
    };

    // National Specials
    const specialsNational = shallowRef<Readonly<SpecialsNational>[]>([]);
    const nationalFilters = reactive({ make: '', model: '' });
    const GET_NATIONAL = async () => {
        try {
            status.value = 'loading';

            specialsNational.value = await specialsApi.getNationalSpecials(
                appConfig.arkonas.site
            );

            status.value = 'loaded';
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
            status.value = 'error';
        }
    };

    const nationalMakes = computed(() => {
        const makes = new Set<string>();

        for (const special of specialsNational.value) {
            makes.add(special.make);
        }

        return Array.from(makes).sort();
    });

    const nationalModels = computed(() => {
        const models = new Set<string>();

        for (const special of specialsNational.value) {
            models.add(special.model);
        }

        return Array.from(models).sort();
    });

    const filteredNationals = computed(() => {
        return specialsNational.value.filter((special) => {
            const makeMatches =
                !nationalFilters.make ||
                nationalFilters.make.toLowerCase() ===
                    special.make.toLowerCase();

            const modelMatches =
                !nationalFilters.model ||
                nationalFilters.model.toLowerCase() ===
                    special.model.toLowerCase();

            return makeMatches && modelMatches;
        });
    });

    const ADD_QUERIES_TO_FILTERS = (route: RouteLocationNormalizedLoaded) => {
        const queries = Object.keys(route.query);

        // If there are no queries, make sure to clear all the filters
        if (!queries.length) {
            for (const key of Object.keys(nationalFilters)) {
                if (isKeyOfObject(key, nationalFilters)) {
                    nationalFilters[key] = '';
                }
            }
        }

        queries.forEach((query) => {
            const queryValue = route.query[query];
            if (!queryValue) return;

            if (isKeyOfObject(query, nationalFilters)) {
                nationalFilters[query] = urlDecode(queryValue.toString());
            }
        });
    };

    return {
        status,
        banner,
        bannerUrl,
        GET_BANNER,
        uniqueMakeModels,
        GET_UNIQUE_NEW_MAKE_MODELS,
        specialsNew,
        disclaimerNew,
        GET_NEW,
        specialsUsed,
        filteredUsed,
        GET_USED,
        specialsCpo,
        filteredCpo,
        GET_CPO,
        specialsClearance,
        GET_CLEARANCE,
        specialsNational,
        nationalFilters,
        GET_NATIONAL,
        nationalMakes,
        nationalModels,
        filteredNationals,
        ADD_QUERIES_TO_FILTERS,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSpecialsStore, import.meta.hot));
}
